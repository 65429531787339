<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>

export default {

  name: "App",
  data() {
    return {
      session: false,
      dark: false,
      route: "",
    };
  },
  computed: {
    
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>