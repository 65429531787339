import Vue from 'vue';
import Router from 'vue-router';


const requireAuth = async(to, from, next) => {

    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
        console.log("USER", user);
        next();
    } else {
        next('/login');
    }
};




const routes = [];
routes.push({ path: '/', name: 'login', redirect: '/login' });
routes.push({
    path: '/login',

    component: () =>
        import ('./pages/login/login_page.vue')
});
routes.push({
    path: '/esqueci-senha',
    component: () =>
        import ('./pages/forgot_password/forgot_password_page.vue')
});




let dataUser = localStorage.getItem('user');

if (dataUser) {
    const user = JSON.parse(dataUser);

    if (user.level == 101) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/admin/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/closure_total',
            component: () =>
                import ('./pages/closure'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/closure/:id',
            component: () =>
                import ('./pages/closure/view.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/escritorios/list',
            component: () =>
                import ('./pages/offices/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/escritorios/relatorio',
            component: () =>
                import ('./pages/reports/office.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/ind_estoque',
            component: () =>
                import ('./pages/products/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/estoque_produto/:id',
            component: () =>
                import ('./pages/products/stock.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/escritorios/add',
            component: () =>
                import ('./pages/offices/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/escritorios/:id',
            component: () =>
                import ('./pages/offices/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/estoque/:id',
            component: () =>
                import ('./pages/stock/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/estoque/itens/:id',
            component: () =>
                import ('./pages/stock/products.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/arquivos/add',
            component: () =>
                import ('./pages/files/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado/:id',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/list',
            component: () =>
                import ('./pages/users/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/list/deletes',
            component: () =>
                import ('./pages/users/list_deleted.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/configurations/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user/:id?',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/level-up',
            component: () =>
                import ('./pages/message/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/check/users',
            component: () =>
                import ('./pages/message/users.vue'),
            beforeEnter: requireAuth
        });


        routes.push({
            path: '/franqueado/vendas/:id',
            component: () =>
                import ('./pages/users/sales.vue'),
            beforeEnter: requireAuth
        });
    }

    if (user.level == 1) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/admin/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/closure_total',
            component: () =>
                import ('./pages/closure'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/closure/:id',
            component: () =>
                import ('./pages/closure/view.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/escritorios/list',
            component: () =>
                import ('./pages/offices/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/escritorios/relatorio',
            component: () =>
                import ('./pages/reports/office.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/volumes',
            component: () =>
                import ('./pages/volumes/list.vue'),
            beforeEnter: requireAuth
        });


        routes.push({
            path: '/volumes/print',
            component: () =>
                import ('./pages/volumes/print.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/depositos',
            component: () =>
                import ('./pages/deposits/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/ind_estoque',
            component: () =>
                import ('./pages/products/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/estoque_produto/:id',
            component: () =>
                import ('./pages/products/stock.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/escritorios/add',
            component: () =>
                import ('./pages/offices/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/escritorios/:id',
            component: () =>
                import ('./pages/offices/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/estoque/:id',
            component: () =>
                import ('./pages/stock/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/estoque/itens/:id',
            component: () =>
                import ('./pages/stock/products.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/arquivos/add',
            component: () =>
                import ('./pages/files/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado/:id',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/list',
            component: () =>
                import ('./pages/users/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/deletes',
            component: () =>
                import ('./pages/users/list_deleted.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/configurations/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user/:id?',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/level-up',
            component: () =>
                import ('./pages/message/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/check/users',
            component: () =>
                import ('./pages/message/users.vue'),
            beforeEnter: requireAuth
        });


        routes.push({
            path: '/franqueado/vendas/:id',
            component: () =>
                import ('./pages/users/sales.vue'),
            beforeEnter: requireAuth
        });
    }

    if (user.level == 2) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/gold_franchisee/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/closure',
            component: () =>
                import ('./pages/closure/view.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/estoque/itens',
            component: () =>
                import ('./pages/stock/products.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/list',
            component: () =>
                import ('./pages/users/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado/:id',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user/:id?',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/users/closure',
            component: () =>
                import ('./pages/users/closure.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/level-up',
            component: () =>
                import ('./pages/message/list.vue'),
            beforeEnter: requireAuth
        });

        routes.push({
            path: '/franqueado/vendas/:id',
            component: () =>
                import ('./pages/users/sales_day.vue'),
            beforeEnter: requireAuth
        });
    }

    if (user.level == 3) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/master_franchisee/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/cadastrar-franqueado',
            component: () =>
                import ('./pages/users/form.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });

        if (user.stock == 1) {
            routes.push({
                path: '/users/closure',
                component: () =>
                    import ('./pages/users/closure.vue'),
                beforeEnter: requireAuth
            });
        }

    }

    if (user.level == 4) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/franchisee/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });

        if (user.stock == 1) {
            routes.push({
                path: '/users/closure',
                component: () =>
                    import ('./pages/users/closure.vue'),
                beforeEnter: requireAuth
            });
        }
    }

    if (user.level == 5) {
        routes.push({
            path: '/painel',
            component: () =>
                import ('./pages/sub_franchisee/dashboard_page.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configurations',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/configuration',
            component: () =>
                import ('./pages/users/configurations.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/profile-user',
            component: () =>
                import ('./pages/users/profile.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/meus-dados',
            component: () =>
                import ('./pages/users/formEdit.vue'),
            beforeEnter: requireAuth
        });
        routes.push({
            path: '/ranking',
            component: () =>
                import ('./pages/ranking/list.vue'),
            beforeEnter: requireAuth
        });
        if (user.stock == 1) {
            routes.push({
                path: '/users/closure',
                component: () =>
                    import ('./pages/users/closure.vue'),
                beforeEnter: requireAuth
            });
        }
    }

    routes.push({
        path: '/developer',
        component: () =>
            import ('./pages/developer/developer.vue'),
        beforeEnter: requireAuth
    });
}
routes.push({
    path: "*",
    component: () => {
        window.location.href = "/login";
    }
});

Vue.use(Router);
export default new Router({
    mode: 'history',
    routes
});